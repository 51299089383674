import React, { useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import Slider from 'react-rangeslider';
import numeral from 'numeral';
import { CircleChevronRightFill } from 'akar-icons';

import 'react-rangeslider/lib/index.css';

const QuotePage = () => {
    const titlestrokeani = {
        duration: 1.65,
        ease: [0.19, 1.0, 0.22, 1.0],
        delay: 1,
    };

    const [form, setForm] = useState({
        api_token: process.env.GATSBY_MAIL_API,
        app_id: process.env.GATSBY_MAIL_APP_ID,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        position: '',
        finance: '',
        amount_type: 0,
        amount: 0,
        revenue_type: 0,
        revenue: 0,
        other: '',
    });
    const [formClass, setFormClass] = useState({
        firstname: 'formitem',
        lastname: 'formitem',
        email: 'formitem',
        phone: 'formitem',
        company: 'formitem',
        position: 'formitem',
        finance: 'formitem fw',
        amount_type: 'formitem fw',
        amount: 'formitem fw',
        revenue_type: 'formitem fw',
        revenue: 'formitem fw',
        other: 'formitem fw',
    });
    const [errorStyle, setErrorStyle] = useState({
        opacity: 0,
        visibility: 'hidden',
        display: 'none',
    });
    const [errorClass, setErrorClass] = useState('formerror');
    const [formVisible, setFormVisible] = useState(true);
    const [errorMsg, setErrorMsg] = useState(
        'Submission Error! Please check the required fields below and try again.'
    );

    const handleChange = (e) => {
        if (e) {
            setForm((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));

            switch (e.target.name) {
                case 'amount_type':
                    setForm((prevState) => ({
                        ...prevState,
                        amount: parseInt(e.target.value) === 1 ? 10000 : 500000,
                    }));
                    break;
                case 'revenue_type':
                    setForm((prevState) => ({
                        ...prevState,
                        revenue:
                            parseInt(e.target.value) === 1 ? 100000 : 10000000,
                    }));
                    break;
                default:
                    break;
            }
        }
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();

            if (
                form.firstname !== '' &&
                form.lastname !== '' &&
                form.email !== ''
            ) {
                fetch(process.env.GATSBY_MAIL_URL, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(
                    () => {
                        setForm((prevState) => ({
                            ...prevState,
                            firstname: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            company: '',
                            position: '',
                            finance: '',
                            amount_type: 0,
                            amount: 0,
                            revenue_type: 0,
                            revenue: 0,
                            other: '',
                        }));

                        setErrorStyle((prevState) => ({
                            ...prevState,
                            opacity: 1,
                            visibility: 'visible',
                            display: 'block',
                        }));
                        setErrorClass('formsuccess');
                        setFormVisible(false);
                        setErrorMsg(
                            'Thank you for requesting a quote. One of our team members will contact you shortly.'
                        );
                    },
                    (error) => {
                        console.info(error);
                    }
                );
            } else {
                setErrorStyle((prevState) => ({
                    ...prevState,
                    opacity: 1,
                    visibility: 'visible',
                    display: 'block',
                }));
                setErrorClass('formerror');
                setErrorMsg(
                    'Submission Error! Please check the required fields below and try again.'
                );

                if (form.firstname === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        firstname: 'formitem inputerror',
                    }));
                }

                if (form.lastname === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        lastname: 'formitem inputerror',
                    }));
                }

                if (form.email === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        email: 'formitem inputerror',
                    }));
                }
            }
        }
    };

    return (
        <Layout>
            <Seo
                title="Get a Quote"
                description="Fill in our quick quote form."
            />
            <div className="app-title">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.45,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="title">
                            <h1>
                                Get a Quote
                                <span>Fill in our quick quote form below.</span>
                            </h1>
                        </div>
                    </motion.div>
                </div>
                <div className="titlestroke">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 1983.911 218.409"
                    >
                        <motion.path
                            d="M7.57 7.57s427.09 139.916 918.524 188.554c355.142 35.149 744.9 22.1 1028.069-128.2"
                            fill="none"
                            stroke="#c2d83d"
                            strokeLinecap="round"
                            strokeWidth="12"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={titlestrokeani}
                        />
                    </svg>
                    <motion.div
                        initial={{ offsetDistance: '0%', scale: 2.5 }}
                        animate={{ offsetDistance: '100%', scale: 1 }}
                        transition={titlestrokeani}
                    />
                </div>
            </div>
            <div className="app-quote">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.65,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="quotetxt">
                            <div>
                                <p>
                                    Fill in your details below and we will get
                                    in touch with you as soon as possible!
                                    Alternatively contact one of our{' '}
                                    <Link to="/contact">team members</Link>
                                </p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.65,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="quote">
                            <div className={errorClass} style={errorStyle}>
                                <span>{errorMsg}</span>
                            </div>
                            <div className="quote__form">
                                {formVisible === true ? (
                                    <>
                                        <div className={formClass.firstname}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="firstname"
                                            >
                                                First Name *
                                            </label>
                                            <div className="formitem__input">
                                                <input
                                                    type="text"
                                                    name="firstname"
                                                    autoComplete="off"
                                                    value={form.firstname}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={formClass.lastname}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="lastname"
                                            >
                                                Last Name *
                                            </label>
                                            <div className="formitem__input">
                                                <input
                                                    type="text"
                                                    name="lastname"
                                                    autoComplete="off"
                                                    value={form.lastname}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={formClass.email}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="email"
                                            >
                                                Email *
                                            </label>
                                            <div className="formitem__input">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    autoComplete="off"
                                                    value={form.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={formClass.phone}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="phone"
                                            >
                                                Contact No.
                                            </label>
                                            <div className="formitem__input">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    autoComplete="off"
                                                    value={form.phone}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={formClass.company}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="company"
                                            >
                                                Company Name
                                            </label>
                                            <div className="formitem__input">
                                                <input
                                                    type="text"
                                                    name="company"
                                                    autoComplete="off"
                                                    value={form.company}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={formClass.position}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="position"
                                            >
                                                Position
                                            </label>
                                            <div className="formitem__input">
                                                <input
                                                    type="text"
                                                    name="position"
                                                    autoComplete="off"
                                                    value={form.position}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={formClass.finance}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="position"
                                            >
                                                What type of finance are you
                                                seeking?
                                            </label>
                                            <div className="formitem__input">
                                                <div className="select">
                                                    <select
                                                        name="finance"
                                                        value={form.finance}
                                                        onChange={handleChange}
                                                    >
                                                        <option
                                                            defaultValue
                                                            hidden
                                                        >
                                                            Please select an
                                                            option
                                                        </option>
                                                        <option value="Business Finance">
                                                            Business Finance
                                                        </option>
                                                        <option value="Equipment Finance">
                                                            Equipment Finance
                                                        </option>
                                                        <option>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={formClass.amount_type}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="position"
                                            >
                                                Loan Amount
                                            </label>
                                            <div className="formitem__input">
                                                <div className="select">
                                                    <select
                                                        name="amount_type"
                                                        value={form.amount_type}
                                                        onChange={handleChange}
                                                    >
                                                        <option
                                                            defaultValue
                                                            hidden
                                                        >
                                                            Please select an
                                                            option
                                                        </option>
                                                        <option value="1">
                                                            10k to 500k
                                                        </option>
                                                        <option value="2">
                                                            500k to 20mil+
                                                        </option>
                                                        <option>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {form.amount_type > 0 ? (
                                            <div className={formClass.amount}>
                                                <div className="formitem__input">
                                                    <div className="slider">
                                                        <Slider
                                                            value={form.amount}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setForm(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        amount: value,
                                                                    })
                                                                );
                                                            }}
                                                            min={
                                                                parseInt(
                                                                    form.amount_type
                                                                ) === 1
                                                                    ? 10000
                                                                    : 500000
                                                            }
                                                            max={
                                                                parseInt(
                                                                    form.amount_type
                                                                ) === 1
                                                                    ? 500000
                                                                    : 20000000
                                                            }
                                                            step={
                                                                parseInt(
                                                                    form.amount_type
                                                                ) === 1
                                                                    ? 1000
                                                                    : 10000
                                                            }
                                                            tooltip={false}
                                                        />
                                                        <div className="value">
                                                            {numeral(
                                                                form.amount
                                                            ).format('$0,0')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className={formClass.revenue_type}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="revenue"
                                            >
                                                Annual Revenue
                                            </label>
                                            <div className="formitem__input">
                                                <div className="select">
                                                    <select
                                                        name="revenue_type"
                                                        value={
                                                            form.revenue_type
                                                        }
                                                        onChange={handleChange}
                                                    >
                                                        <option
                                                            defaultValue
                                                            hidden
                                                        >
                                                            Please select an
                                                            option
                                                        </option>
                                                        <option value="1">
                                                            100k to 10mil
                                                        </option>
                                                        <option value="2">
                                                            10mil to 100mil+
                                                        </option>
                                                        <option>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {form.revenue_type > 0 ? (
                                            <div className={formClass.revenue}>
                                                <div className="formitem__input">
                                                    <div className="slider">
                                                        <Slider
                                                            value={form.revenue}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setForm(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        revenue:
                                                                            value,
                                                                    })
                                                                );
                                                            }}
                                                            min={
                                                                parseInt(
                                                                    form.revenue_type
                                                                ) === 1
                                                                    ? 100000
                                                                    : 10000000
                                                            }
                                                            max={
                                                                parseInt(
                                                                    form.revenue_type
                                                                ) === 1
                                                                    ? 10000000
                                                                    : 100000000
                                                            }
                                                            step={
                                                                parseInt(
                                                                    form.amount_type
                                                                ) === 1
                                                                    ? 10000
                                                                    : 100000
                                                            }
                                                            tooltip={false}
                                                        />
                                                        <div className="value">
                                                            {numeral(
                                                                form.revenue
                                                            ).format('$0,0')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className={formClass.other}>
                                            <label
                                                className="formitem__label"
                                                htmlFor="other"
                                            >
                                                Other Details
                                            </label>
                                            <div className="formitem__input">
                                                <textarea
                                                    name="other"
                                                    value={form.other}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="formitem fw">
                                            <button
                                                className="btn"
                                                onClick={handleSubmit}
                                            >
                                                Submit{' '}
                                                <CircleChevronRightFill
                                                    strokeWidth={2}
                                                    size={35}
                                                />
                                            </button>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
};

export default QuotePage;
